export enum FEATURE_FLAGS {
    SpellingGrammarMetrics = "spelling_grammar_metrics",
    GrammarMetric = "grammar_metric",
    WritingIssuesFilter = "writing_issues_filter",
    EditInteractionTags = "edit_interaction_tags",
    AddTagsToInteractionMessage = "add_tags_to_interaction_message",
    FilterTags = "filter_tags",
    CustomizableExploreCharts = "customizable_explore_charts",
    ManageTeamsScreen = "manage_teams_screen",
    Scorecards = "scorecards",
    ScorecardsAgentsLogin = "scorecards_agents_login",
    FancyScorecardAddButton = "fancy_scorecard_add_button",
    AnimatedScorecardProgress = "animated_scorecard_progress",
    AgentsTableRefactor = "agents_table_refactor",
    EmpathySentiment = "empathy_sentiment",
    ConfusionSentiment = "confusion_sentiment",
}
