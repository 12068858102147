import { Permission } from "@edgetier/watchtower-types";
import { PhraseTriggerInfoBreadcrumb } from "components-for/breadcrumbs/breadcrumb-item/specifics/phrase-trigger-info-breadcrumb/phrase-trigger-info-breadcrumb";
import { TagInfoBreadcrumb } from "components-for/breadcrumbs/breadcrumb-item/specifics/tag-info-breadcrumb/tag-info-breadcrumb";
import { TeamInfoBreadcrumb } from "components-for/breadcrumbs/breadcrumb-item/specifics/team-info-breadcrumb/team-info-breadcrumb";
import { RedirectToIndex } from "components-for/routes/redirect-to-index";
import { Navigate } from "react-router-dom";
import { FEATURE_FLAGS } from "types-for/feature-flags";
import { IRouteHandle, IRouteHierarchy, PageNames } from "types-for/routes";

const RoutesHierarchy: IRouteHierarchy = {
    Root: {
        lazy: () => import("components-for/application/layout"),
        children: {
            [PageNames.Login]: {
                path: "/login",
                lazy: () => import("pages/login"),
            },
            [PageNames.Authenticate]: {
                path: "/authenticate",
                lazy: () => import("pages/authenticate"),
            },
            [PageNames.AuthenticateRefresh]: {
                path: "/authenticate-refresh",
                lazy: () => import("pages/authenticate-refresh"),
            },
            // This FakeMain route is used to fix the old routes using the /main prefix
            FakeMain: {
                path: "/main/*",
                lazy: () => import("pages/main-redirect"),
            },
            [PageNames.Main]: {
                path: "/",
                lazy: () => import("components-for/application/routes/authenticated-routes"),
                children: {
                    MainRoot: {
                        path: "",
                        element: <RedirectToIndex />,
                    },
                    [PageNames.Agent]: {
                        path: "agent",
                        lazy: () => import("pages/agent"),
                        handle: {
                            permission: [Permission.ViewAgent, Permission.ViewAgentDetails],
                            operation: "AND",
                            enabled: {
                                clientKey: "isAgentReviewEnabled",
                                validation: Boolean,
                            },
                        },
                    },
                    [PageNames.Agents]: {
                        path: "agents",
                        lazy: () => import("pages/agents"),
                        handle: {
                            permission: Permission.ViewAgent,
                            enabled: {
                                clientKey: "isAgentReviewEnabled",
                                validation: Boolean,
                            },
                        },
                    },
                    [PageNames.Reviews]: {
                        path: "reviews",
                        lazy: () => import("pages/reviews"),
                        handle: {
                            permission: [
                                Permission.ManageAllReviews,
                                Permission.ViewReceivedReviews,
                                Permission.ManageOwnReviews,
                            ],
                            enabled: {
                                validation: {
                                    featureFlag: FEATURE_FLAGS.Scorecards,
                                },
                            },
                        },
                    },
                    [PageNames.Anomalies]: {
                        path: "anomalies",
                        lazy: () => import("pages/anomalies"),
                        handle: { permission: Permission.ViewAnomalies },
                    },
                    [PageNames.Anomaly]: {
                        path: "anomaly/:anomalyId",
                        lazy: () => import("pages/anomaly"),
                        handle: { permission: Permission.ViewAnomalies },
                    },
                    [PageNames.CustomReports]: {
                        path: "custom-reports",
                        lazy: () => import("pages/custom-reports"),
                        handle: { permission: Permission.ViewCustomReports },
                    },
                    [PageNames.CustomReportsDashboard]: {
                        path: "/custom-reports/:metabaseDashboardId",
                        lazy: () => import("pages/custom-reports/metabase-dashboard"),
                        handle: { permission: Permission.ViewCustomReports },
                    },
                    [PageNames.Explore]: {
                        path: "explore",
                        lazy: () => import("pages/explore"),
                        handle: { permission: Permission.ViewExplore },
                    },
                    [PageNames.ExploreTags]: {
                        path: "explore-tags",
                        lazy: () => import("pages/comparison-tags"),
                        handle: {
                            permission: Permission.ViewExplore,
                            enabled: [
                                {
                                    clientKey: "isExploreTagsEnabled",
                                    validation: Boolean,
                                },
                            ],
                        },
                    },
                    [PageNames.Interaction]: {
                        path: "interaction/:interactionId",
                        lazy: () => import("pages/interaction"),
                    },
                    [PageNames.ReviewInteraction]: {
                        path: "review/:interactionId",
                        lazy: () => import("pages/review-interaction"),
                        handle: {
                            permission: Permission.ViewAgentDetails,
                            enabled: {
                                validation: { featureFlag: FEATURE_FLAGS.Scorecards },
                            },
                        },
                    },
                    [PageNames.Live]: {
                        path: "live",
                        lazy: () => import("pages/live"),
                        handle: {
                            permission: Permission.ViewLive,
                            enabled: {
                                clientKey: "isLiveViewEnabled",
                                validation: Boolean,
                            },
                        },
                    },
                    Configuration: {
                        path: "configuration",
                        handle: { crumb: () => ({ title: "Configuration" }) },
                        children: {
                            MainConfiguration: {
                                path: "",
                                element: <Navigate to="manage-tags" replace />,
                            },
                            [PageNames.ManageTags]: {
                                path: "manage-tags",
                                lazy: () => import("pages/tag-phrase-triggers"),
                                handle: {
                                    permission: { type: "tags" },
                                    crumb: () => ({ title: "Tag & Triggers" }),
                                },
                                children: {
                                    [PageNames.ViewPhraseTriggers]: {
                                        path: ":tagId",
                                        lazy: () => import("pages/tag-phrase-triggers/phrase-triggers"),
                                        handle: {
                                            crumb: (params) => [
                                                {
                                                    title: <TagInfoBreadcrumb tagId={params.tagId || ""} />,
                                                    url: AbsolutePaths[PageNames.ViewPhraseTriggers](
                                                        params.tagId || ""
                                                    ),
                                                },
                                            ],
                                        },
                                    },
                                    PhraseTriggers: {
                                        path: ":tagId/phrase-triggers",
                                        handle: {
                                            crumb: (params) => [
                                                {
                                                    title: <TagInfoBreadcrumb tagId={params.tagId || ""} />,
                                                    url: AbsolutePaths[PageNames.ViewPhraseTriggers](
                                                        params.tagId || ""
                                                    ),
                                                },
                                                { title: "Phrase Triggers" },
                                            ],
                                        },
                                        children: {
                                            [PageNames.CreatePhraseTriggers]: {
                                                path: "create",
                                                lazy: () =>
                                                    import(
                                                        "pages/tag-phrase-triggers/phrase-triggers/edit-phrase-trigger"
                                                    ),
                                                handle: {
                                                    crumb: (params) => [
                                                        {
                                                            title: (
                                                                <PhraseTriggerInfoBreadcrumb
                                                                    phraseTriggerId={params.phraseTriggerId || ""}
                                                                />
                                                            ),
                                                            url: AbsolutePaths[PageNames.CreatePhraseTriggers](
                                                                params.tagId || ""
                                                            ),
                                                        },
                                                    ],
                                                },
                                            },
                                            [PageNames.PhraseTriggersReport]: {
                                                path: ":phraseTriggerId",
                                                lazy: () =>
                                                    import(
                                                        "pages/tag-phrase-triggers/phrase-triggers-performance-report"
                                                    ),
                                                handle: {
                                                    crumb: (params) => [
                                                        {
                                                            title: (
                                                                <PhraseTriggerInfoBreadcrumb
                                                                    phraseTriggerId={params.phraseTriggerId || ""}
                                                                />
                                                            ),
                                                            url: AbsolutePaths[PageNames.PhraseTriggersReport](
                                                                params.tagId || "",
                                                                params.phraseTriggerId || ""
                                                            ),
                                                        },
                                                    ],
                                                },
                                            },
                                            [PageNames.ManagePhraseTriggers]: {
                                                path: ":phraseTriggerId/edit",
                                                lazy: () =>
                                                    import(
                                                        "pages/tag-phrase-triggers/phrase-triggers/edit-phrase-trigger"
                                                    ),
                                                handle: {
                                                    crumb: (params) => [
                                                        {
                                                            title: (
                                                                <PhraseTriggerInfoBreadcrumb
                                                                    phraseTriggerId={params.phraseTriggerId || ""}
                                                                />
                                                            ),
                                                            url: AbsolutePaths[PageNames.PhraseTriggersReport](
                                                                params.tagId || "",
                                                                params.phraseTriggerId || ""
                                                            ),
                                                        },
                                                        {
                                                            title: "Edit Phrase Trigger",
                                                            url: AbsolutePaths[PageNames.ManagePhraseTriggers](
                                                                params.tagId || "",
                                                                params.phraseTriggerId || ""
                                                            ),
                                                        },
                                                    ],
                                                },
                                            },
                                        },
                                    },
                                },
                            },
                            [PageNames.AgentGroup]: {
                                path: "agent-groups/:tagId?",
                                lazy: () => import("pages/agent-groups"),
                                handle: {
                                    permission: Permission.ManageAgentTags,
                                    enabled: {
                                        clientKey: "isAgentReviewEnabled",
                                        validation: Boolean,
                                    },
                                },
                            },
                            [PageNames.SpellingConfiguration]: {
                                path: "spelling-configuration",
                                lazy: () => import("pages/spelling-configuration"),
                                handle: { permission: Permission.ManageWritingIssues },
                            },
                        },
                    },
                    [PageNames.Permission]: {
                        path: "permissions",
                        lazy: () => import("pages/permissions"),
                        handle: { permission: Permission.ManageRoles },
                    },
                    [PageNames.Teams]: {
                        path: "teams",
                        lazy: () => import("pages/teams"),
                        handle: {
                            permission: Permission.ManageTeams,
                            enabled: {
                                validation: { featureFlag: FEATURE_FLAGS.ManageTeamsScreen },
                            },
                        },
                        children: {
                            [PageNames.TeamsConfiguration]: {
                                path: "",
                                lazy: () => import("pages/teams/teams-configuration"),
                                handle: {
                                    permission: Permission.ManageTeams,
                                    enabled: {
                                        validation: { featureFlag: FEATURE_FLAGS.ManageTeamsScreen },
                                    },
                                },
                            },
                            [PageNames.CreateTeamConfiguration]: {
                                path: "create",
                                lazy: () => import("pages/teams/edit-team-configuration"),
                                handle: {
                                    permission: Permission.ManageTeams,
                                    enabled: {
                                        validation: { featureFlag: FEATURE_FLAGS.ManageTeamsScreen },
                                    },
                                    crumb: () => [
                                        {
                                            title: "Teams Overview",
                                            url: AbsolutePaths[PageNames.TeamsConfiguration](),
                                        },
                                        {
                                            title: "Create Team",
                                        },
                                    ],
                                },
                            },
                            [PageNames.EditTeamConfiguration]: {
                                path: ":teamId",
                                lazy: () => import("pages/teams/edit-team-configuration"),
                                handle: {
                                    permission: Permission.ManageTeams,
                                    enabled: {
                                        validation: { featureFlag: FEATURE_FLAGS.ManageTeamsScreen },
                                    },
                                    crumb: (params) => [
                                        {
                                            title: "Teams Overview",
                                            url: AbsolutePaths[PageNames.TeamsConfiguration](),
                                        },
                                        {
                                            title: <TeamInfoBreadcrumb teamId={params.teamId || ""} />,
                                        },
                                        {
                                            title: "Edit Team",
                                        },
                                    ],
                                },
                            },
                        },
                    },
                    [PageNames.Users]: {
                        path: "users",
                        lazy: () => import("pages/users"),
                        handle: { permission: Permission.ManageUsers },
                    },
                },
            },
            [PageNames.NoMatch]: {
                path: "*",
                lazy: () => import("@edgetier/client-components").then((module) => ({ default: module.NoMatch })),
            },
        },
    },
};

/**
 * Function to get the handle object of a given PageName
 * @param pageName      The page name of the route we want the handle for.
 * @param hierarchy     The routes hierarchy to search in. Defaults to the app's routes hierarchy.
 * @returns             The handle object of the given PageName, or null if not found.
 */
export const getRouteHandle = (
    pageName: PageNames,
    hierarchy: IRouteHierarchy = RoutesHierarchy
): IRouteHandle | null => {
    const pageConfig = hierarchy[pageName];
    if (typeof pageConfig?.handle !== "undefined") {
        return pageConfig.handle;
    } else {
        for (const key in hierarchy) {
            if (hierarchy[key].children) {
                const nestedHandle = getRouteHandle(pageName, hierarchy[key].children);
                if (nestedHandle !== null) {
                    return nestedHandle;
                }
            }
        }
    }
    return null;
};

/**
 * Function to get the absolute path of a given PageName
 * @param pageName      The page name of the route we want the absolute path for.
 * @param hierarchy     The routes hierarchy to search in. Defaults to the app's routes hierarchy.
 * @returns             A function that can be called to get the absolute path of a given PageName. If the provided route has parameters, just send them to the function returned by this function.
 */
export const getAbsolutePath = (pageName: PageNames, hierarchy: IRouteHierarchy = RoutesHierarchy): string | null => {
    const pageConfig = hierarchy[pageName];
    if (pageConfig) {
        return pageConfig.path || "/";
    } else {
        for (const key in hierarchy) {
            if (hierarchy[key].children) {
                const nestedPath = getAbsolutePath(pageName, hierarchy[key].children);
                if (nestedPath) {
                    return (hierarchy[key].path || "") + (nestedPath[0] === "/" ? nestedPath : `/${nestedPath}`);
                }
            }
        }
    }
    return null;
};

/**
 * Retrieve the relative path to a specific page from a given origin page.
 * @param currentPageName PageName of the origin page.
 * @param destinationPageName PageName of the destination page.
 * @param hierarchy The routes hierarchy to search in. Defaults to the app's routes hierarchy.
 * @returns The relative path to the destination page from the origin page.
 */
export const getRelativePath = (
    currentPageName: PageNames,
    destinationPageName: PageNames,
    hierarchy?: IRouteHierarchy
): string | null => {
    const currentAbsPath = getAbsolutePath(currentPageName, hierarchy);
    const destinationAbsPath = getAbsolutePath(destinationPageName, hierarchy);

    if (!currentAbsPath || !destinationAbsPath) {
        return null;
    }

    const currentParts = currentAbsPath.split("/");
    const destinationParts = destinationAbsPath.split("/");

    let i = 0;
    while (i < currentParts.length && i < destinationParts.length && currentParts[i] === destinationParts[i]) {
        i++;
    }

    const backPaths = new Array(currentParts.length - i).fill("..");
    const forwardPaths = destinationParts.slice(i);

    return [...backPaths, ...forwardPaths].join("/");
};

const AbsolutePaths: { [pageName in PageNames]: (...args: Array<string>) => string } = Object.keys(PageNames).reduce(
    (acc, pageName) => {
        const pageNameKey = pageName as PageNames;
        const path = getAbsolutePath(pageNameKey, RoutesHierarchy)?.replace("//", "/");
        if (!path) {
            throw new Error(`No path found for page name ${pageNameKey}`);
        }
        if (pageNameKey !== PageNames.NoMatch) {
            acc[pageNameKey] = (...args: Array<string>) => {
                let localPath = path;
                const paramMatches = localPath.match(/:\w+\??/g);
                if (paramMatches && paramMatches.length === args.length) {
                    paramMatches.forEach((param, index) => {
                        localPath = localPath.replace(param, args[index] || "");
                    });
                }
                return localPath;
            };
        }
        return acc;
    },
    {} as { [pageName in PageNames]: (...args: Array<string>) => string }
);

export { AbsolutePaths };

export default RoutesHierarchy;
