import { NoMatch } from "@edgetier/client-components";
import { SpinnerUntil } from "@edgetier/components";
import usePermission from "hooks-for/permissions/use-permission";
import { FunctionComponent, memo, useMemo } from "react";

import "./verify-permission.scss";
import { IProps } from "./verify-permission.types";

/**
 * Display page or element when the user has permission for it.
 * @param props.children   Element displayed.
 * @param props.finalCheck A function that's given the usePermission results and makes the final permission decision.
 * @param props.permission The permission(s) to check. If multiple are passed, the permissions are OR-ed.
 * @param props.isRoute    For routes, don't show the spinner.
 * @param props.operation  The operation to use when checking multiple permissions. Defaults to "OR".
 */
const VerifyPermission: FunctionComponent<IProps> = ({
    name,
    children,
    finalCheck,
    permission,
    isRoute = false,
    operation = "OR",
}) => {
    const { hasPermission: initialHasPermission, permissions, isLoading } = usePermission(permission);

    const hasPermission = useMemo(() => {
        const passedPermissions = Array.isArray(permission) ? permission : [permission];
        const userHasPermissions =
            operation === "AND"
                ? passedPermissions.every((p) => permissions.map(({ permissionId }) => permissionId).includes(p))
                : initialHasPermission;

        if (typeof finalCheck !== "undefined" && userHasPermissions === true) {
            return finalCheck(permissions);
        }
        return userHasPermissions;
    }, [finalCheck, initialHasPermission, permissions, operation, permission]);

    return (
        <SpinnerUntil isReady={!isLoading} data={hasPermission}>
            {isRoute ? <>{hasPermission ? <>{children}</> : <NoMatch />}</> : <>{hasPermission && children}</>}
        </SpinnerUntil>
    );
};

export default memo(VerifyPermission);
